import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import DateFormat from '../common/dateFormat/DateFormat';
import historico from './historico';

export function setModoTela(modo, registro = { id_tipo: 1 }) {
	return {
		type: 'CLIENTE_MODO_TELA',
		payload: {
			modo: modo,
			registro: registro
		}
	};
}
export function initForm(registro = { id_tipo: 1 }) {
	return [
		initialize('clienteForm', registro)
	];
}

export function setFiltro(filtro) {
	return {
		type: 'CLIENTE_FILTRO',
		payload: filtro
	};
}

export function getLista(convite = false) {

	return (dispatch, getState) => {

		dispatch({
			type: 'CLIENTE_LISTADO',
			payload: { data: [] }
		});

		let dataInicial = DateFormat.formatarDataTelaParaParametro(getState().cliente.filtro.dataInicial);
		let dataFinal = DateFormat.formatarDataTelaParaParametro(getState().cliente.filtro.dataFinal);

		dataInicial = dataInicial && dataInicial == 'Invalid DateTime' ? null : dataInicial;
		dataFinal = dataFinal && dataFinal == 'Invalid DateTime' ? null : dataFinal;

		if (convite) {
			axios.get(`${consts.API_URL}/cliente?
				data_inicial=${dataInicial}
				&data_final=${dataFinal}
				&id_vendedor=${getState().cliente.filtro.id_vendedor || null}
				&id_representante=${getState().cliente.filtro.id_representante || null}
				&convite=true`)
				.then(resp => {
					dispatch({
						type: 'CLIENTE_LISTADO',
						payload: resp
					});
				}).catch(ex => {

				});
		} else {
			//console.log(getState().cliente.filtro.status);
			axios.get(`${consts.API_URL}/cliente?
				data_inicial=${dataInicial}
				&data_final=${dataFinal}
				&id_vendedor=${getState().cliente.filtro.id_vendedor || null}
				&id_representante=${getState().cliente.filtro.id_representante || null}
				&status=${getState().cliente.filtro.status.length > 0 ? getState().cliente.filtro.status : null}`)
				.then(resp => {
					dispatch({
						type: 'CLIENTE_LISTADO',
						payload: resp
					});
					//console.log(resp.data.filter(item => item.id_status != 2));
				}).catch(ex => {

				});
		}
	};
}

export function getListaMensagem() {

	return (dispatch, getState) => {

		axios.get(`${consts.OAPI_URL}/mensagem`)
			.then(resp => {
				dispatch({
					type: 'CLIENTE_MENSAGEM_LISTADO',
					payload: resp
				});
			}).catch(ex => {

			});
	};
}


export function getListaMensagemHistorico() {
	return (dispatch, getState) => {
		axios.get(`${consts.OAPI_URL}/mensagem/historico?
			data_inicial=${DateFormat.formatarDataTelaParaSql(getState().cliente.filtro.data_inicial) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().cliente.filtro.data_inicial)}
			&data_final=${DateFormat.formatarDataTelaParaSql(getState().cliente.filtro.data_final) == 'Invalid DateTime' ? null : DateFormat.formatarDataTelaParaSql(getState().cliente.filtro.data_final)}
		`).then(resp => {
			dispatch({
				type: 'CLIENTE_MENSAGEM_HISTORICO_LISTADO',
				payload: resp
			});
		}).catch(ex => {
			console.log(ex);
		});
	};
}

export function getRegistro(id) {

	return (dispatch, getState) => {
		axios.get(`${consts.API_URL}/cliente/buscar?id=${id}`)
			.then(resp => {
				dispatch({
					type: 'CLIENTE_REGISTRO_LISTADO',
					payload: resp
				});

				dispatch(initForm({
					...resp.data,
					data_venda: DateFormat.formatarDataSqlParaTela(resp.data.data_venda),
					data_nascimento: resp.data.data_nascimento ? DateFormat.formatarDataSqlParaTela(resp.data.data_nascimento) : null,
					itens: resp.data.itens.map(itemPgto => ({
						...itemPgto,
						data_vencimento: DateFormat.formatarDataSqlParaTela(itemPgto.data_vencimento)
					}))
				}));
			}).catch(ex => {

			});
	};
}


export function getListaClienteTipo() {
	const request = axios.get(`${consts.API_URL}/clienteTipo/listarSelect`);
	return {
		type: 'CLIENTE_TIPO_SELECT_LISTADO',
		payload: request
	};
}

export function getListaClienteStatus() {
	const request = axios.get(`${consts.API_URL}/clienteStatus/listarSelect`);
	return {
		type: 'CLIENTE_STATUS_SELECT_LISTADO',
		payload: request
	};
}

export function getListaEstadoCivil() {
	const request = axios.get(`${consts.API_URL}/estadoCivil/listarSelect`);
	return {
		type: 'CLIENTE_ESTADO_CIVIL_SELECT_LISTADO',
		payload: request
	};
}

export function getListaFormaPagamento() {
	const request = axios.get(`${consts.API_URL}/formaPagamento/listarSelect`);
	return {
		type: 'CLIENTE_FORMA_PAGAMENTO_SELECT_LISTADO',
		payload: request
	};
}

export function getListaOperadoraTelefonica() {
	const request = axios.get(`${consts.API_URL}/operadoraTelefonica/listarSelect`);
	return {
		type: 'CLIENTE_OPERADORA_TELEFONICA_SELECT_LISTADO',
		payload: request
	};
}

export function getListaTipoContrato() {
	const request = axios.get(`${consts.API_URL}/tipoContrato/listarSelect`);
	return {
		type: 'CLIENTE_TIPO_CONTRATO_SELECT_LISTADO',
		payload: request
	};
}

export function getListaUsuario() {
	const request = axios.get(`${consts.API_URL}/usuario/listarSelect`);
	return {
		type: 'CLIENTE_USUARIO_SELECT_LISTADO',
		payload: request
	};
}

export function getListaEstado() {
	const request = axios.get(`${consts.API_URL}/estado/listarSelect`);
	return {
		type: 'CLIENTE_ESTADO_SELECT_LISTADO',
		payload: request
	};
}

export function getListaCidade() {
	const request = axios.get(`${consts.API_URL}/cidade/listarSelect`);
	return {
		type: 'CLIENTE_CIDADE_SELECT_LISTADO',
		payload: request
	};
}

export function salvar(registro) {
	return (dispatch, getState) => {

		if (!registro.id) {
			axios.post(`${consts.API_URL}/cliente`, {
				...registro,
				data_venda: registro.data_venda ? DateFormat.formatarDataTelaParaSql(registro.data_venda) : null,
				data_nascimento: registro.data_nascimento ? DateFormat.formatarDataTelaParaSql(registro.data_nascimento) : null,
				cpf: (registro.cpf || '').split(".").join("").split("-").join(""),
				cep: (registro.cep || '').split(".").join("").split("-").join(""),
				telefone_fixo: (registro.telefone_fixo || '').split(".").join("").split("-").join(""),
				celular1: (registro.celular1 || '').split(".").join("").split("-").join(""),
				celular2: (registro.celular2 || '').split(".").join("").split("-").join(""),
				dependente_cpf1: (registro.dependente_cpf1 || '').split(".").join("").split("-").join(""),
				dependente_celular1: (registro.dependente_celular1 || '').split(".").join("").split("-").join(""),
				dependente_cpf2: (registro.dependente_cpf2 || '').split(".").join("").split("-").join(""),
				dependente_celular2: (registro.dependente_celular2 || '').split(".").join("").split("-").join(""),
				dependente_cpf3: (registro.dependente_cpf3 || '').split(".").join("").split("-").join(""),
				dependente_celular3: (registro.dependente_celular3 || '').split(".").join("").split("-").join(""),
				dependente_cpf4: (registro.dependente_cpf4 || '').split(".").join("").split("-").join(""),
				dependente_celular4: (registro.dependente_celular4 || '').split(".").join("").split("-").join(""),
				dependente_cpf5: (registro.dependente_cpf5 || '').split(".").join("").split("-").join(""),
				dependente_celular5: (registro.dependente_celular5 || '').split(".").join("").split("-").join(""),
				itens: (registro.itens || []).map(item => ({
					...item,
					data_vencimento: DateFormat.formatarDataTelaParaSql(item.data_vencimento)
				}))
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(registro.id_tipo == 2));
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		} else {
			axios.put(`${consts.API_URL}/cliente`, {
				...registro,
				data_venda: registro.data_venda ? DateFormat.formatarDataTelaParaSql(registro.data_venda) : null,
				data_nascimento: registro.data_nascimento ? DateFormat.formatarDataTelaParaSql(registro.data_nascimento) : null,
				cpf: (registro.cpf || '').split(".").join("").split("-").join(""),
				cep: (registro.cep || '').split(".").join("").split("-").join(""),
				telefone_fixo: (registro.telefone_fixo || '').split(".").join("").split("-").join(""),
				celular1: (registro.celular1 || '').split(".").join("").split("-").join(""),
				celular2: (registro.celular2 || '').split(".").join("").split("-").join(""),
				dependente_cpf1: (registro.dependente_cpf1 || '').split(".").join("").split("-").join(""),
				dependente_celular1: (registro.dependente_celular1 || '').split(".").join("").split("-").join(""),
				dependente_cpf2: (registro.dependente_cpf2 || '').split(".").join("").split("-").join(""),
				dependente_celular2: (registro.dependente_celular2 || '').split(".").join("").split("-").join(""),
				dependente_cpf3: (registro.dependente_cpf3 || '').split(".").join("").split("-").join(""),
				dependente_celular3: (registro.dependente_celular3 || '').split(".").join("").split("-").join(""),
				dependente_cpf4: (registro.dependente_cpf4 || '').split(".").join("").split("-").join(""),
				dependente_celular4: (registro.dependente_celular4 || '').split(".").join("").split("-").join(""),
				dependente_cpf5: (registro.dependente_cpf5 || '').split(".").join("").split("-").join(""),
				dependente_celular5: (registro.dependente_celular5 || '').split(".").join("").split("-").join(""),
				itens: (registro.itens || []).map(item => ({
					...item,
					data_vencimento: DateFormat.formatarDataTelaParaSql(item.data_vencimento)
				}))
			}).then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(registro.id_tipo == 2));
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
		}
	};
}

export function setMensagemLida(registro) {
	return (dispatch, getState) => {

		axios.put(`${consts.OAPI_URL}/mensagem`, {
			...registro
		}).then(resp => {
			dispatch(getListaMensagem());
		}).catch(e => {
			setErroAPI(e);
		});
	};
}

export function reservarConvite(registro) {
	return (dispatch, getState) => {
		axios.put(`${consts.API_URL}/cliente/reservarConvite`, {
			...registro,
			data_reserva_convite: registro.data_reserva_convite ? DateFormat.formatarDataTelaParaSql(registro.data_reserva_convite) : null
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista(registro.id_tipo == 2));
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
	};
}

export function excluir(registro) {
	return dispatch => {
		axios.delete(`${consts.API_URL}/cliente?id=${registro.id}`)
			.then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(registro.id_tipo == 2));
				dispatch(setModoTela('lista'));
			}).catch(e => {
				setErroAPI(e);
			});
	};
}

export function importarArquivo(registro, base64) {
	return (dispatch, getState) => {

		axios.post(`${consts.API_URL}/clienteDocumento`, {
			id_cliente: registro.id,
			arquivo: base64
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaDocumento(registro.id));
		}).catch(e => {
			console.log(e);
			try {
				setErroAPI(e);
			} catch (ex) {
				console.log(ex);
			}
		});

	};
}

export function excluirArquivo(registro) {
	return (dispatch, getState) => {

		axios.delete(`${consts.API_URL}/clienteDocumento?id=${registro.id}`).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaDocumento(registro.id_cliente));
		}).catch(e => {
			console.log(e);
			try {
				setErroAPI(e);
			} catch (ex) {
				console.log(ex);
			}
		});

	};
}

export function getListaDocumento(id_cliente) {
	return (dispatch, getState) => {

		dispatch({
			type: 'CLIENTE_DOCUMENTO_LISTADO',
			payload: { data: [] }
		});
		
		if (id_cliente) {
			axios.get(`${consts.API_URL}/clienteDocumento?id_cliente=${id_cliente}`).then(resp => {
				dispatch({
					type: 'CLIENTE_DOCUMENTO_LISTADO',
					payload: resp
				});
			}).catch(e => {
				try {
					setErroAPI(e);
				} catch (ex) {
					console.log(ex);
				}
			});
		}
	}
}

export function getListaRelatorio() {
	const request = axios.get(`${consts.API_URL}/clienteMensalidade/listarRelatorio`);
	return {
		type: 'CLIENTE_RELATORIO_LISTADO',
		payload: request
	};
}

export function getListaCompetencia() {
	const request = axios.get(`${consts.API_URL}/clienteMensalidade/listarSelect`);
	return {
		type: 'CLIENTE_COMPETENCIA_SELECT_LISTADO',
		payload: request
	};
}

export function limparAcesso(registro) {
	return (dispatch, getState) => {

		axios.put(`${consts.API_URL}/cliente/limparAcesso`, {
			...registro,

		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista(registro.id_tipo == 2));
			dispatch(setModoTela('lista'));
		}).catch(e => {
			setErroAPI(e);
		});
	}
};
