import DateFormat from '../common/dateFormat/DateFormat';

const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	lista: [],
	listaRelatorio: [],
	listaClienteTipo: [],
	listaClienteStatus: [],
	listaEstadoCivil: [],
	listaFormaPagamento: [],
	listaOperadoraTelefonica: [],
	listaTipoContrato: [],
	listaUsuario: [],
	getListaEstado: [],
	listaCidade: [],
	listaDocumento: [],
	listaMensagem: [],
	listaHistorico:[],
	listaCompetencia: [],
	filtro: {
		dataInicial: null,
		dataFinal: null,
		data_inicial: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAnterior(30)),
		data_final: DateFormat.formatarDataSqlParaTela(DateFormat.getDataAtual()),
		id_usuario: null,
		id_equipamento: null,
		status: [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],
	},
	listaFormaContrato: [
		{ id: 'C.O em pdf', valor : 'C.O em pdf' },
		{ id: 'C. ausente', valor : 'C. ausente' },
		{ id: 'C. foto', valor : 'C. foto' },
		{ id: 'C.O.S.A verso', valor : 'C.O.S.A verso' },
		{ id: 'C.O completo', valor : 'C.O completo' }
	]
};


export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case 'CLIENTE_MODO_TELA':
			return {
				...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};
		case 'CLIENTE_FILTRO':
			return { ...state, filtro: action.payload };
		case 'CLIENTE_LISTADO':
			// console.log(action.payload.data);
			return { ...state, modoTela: 'lista', lista: action.payload.data };
		case 'CLIENTE_MENSAGEM_LISTADO':
			//console.log(action.payload.data);
			return { ...state, listaMensagem: action.payload.data };

		case 'CLIENTE_MENSAGEM_HISTORICO_LISTADO':
			console.log(action.payload.data);
			return { ...state, listaHistorico: action.payload.data };

		case 'CLIENTE_REGISTRO_LISTADO':
			return { ...state, registro: action.payload.data };

		case 'CLIENTE_DOCUMENTO_LISTADO':
			return { ...state, listaDocumento: action.payload.data };

		case 'CLIENTE_TIPO_SELECT_LISTADO':
			return { ...state, listaClienteTipo: action.payload.data };

		case 'CLIENTE_STATUS_SELECT_LISTADO':
			return { ...state, listaClienteStatus: action.payload.data };

		case 'CLIENTE_ESTADO_CIVIL_SELECT_LISTADO':
			return { ...state, listaEstadoCivil: action.payload.data };

		case 'CLIENTE_FORMA_PAGAMENTO_SELECT_LISTADO':
			return { ...state, listaFormaPagamento: action.payload.data };

		case 'CLIENTE_OPERADORA_TELEFONICA_SELECT_LISTADO':
			return { ...state, listaOperadoraTelefonica: action.payload.data };

		case 'CLIENTE_TIPO_CONTRATO_SELECT_LISTADO':
			return { ...state, listaTipoContrato: action.payload.data };

		case 'CLIENTE_USUARIO_SELECT_LISTADO':
			return { ...state, listaUsuario: action.payload.data };

		case 'CLIENTE_ESTADO_SELECT_LISTADO':
			return { ...state, listaEstado: action.payload.data };

		case 'CLIENTE_CIDADE_SELECT_LISTADO':
			return { ...state, listaCidade: action.payload.data };

		case 'CLIENTE_RELATORIO_LISTADO':
			return { ...state, listaRelatorio: action.payload.data };

		case 'CLIENTE_COMPETENCIA_SELECT_LISTADO':
			return { ...state, listaCompetencia: action.payload.data };



		default:
			return state;
	}
}
